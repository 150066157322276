import { render, staticRenderFns } from "./save-lists.html?vue&type=template&id=28bf33d6&scoped=true&"
import script from "./save-lists.ts?vue&type=script&lang=ts&"
export * from "./save-lists.ts?vue&type=script&lang=ts&"
import style0 from "./save-lists.scss?vue&type=style&index=0&id=28bf33d6&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "28bf33d6",
  null
  
)

/* custom blocks */
import block0 from "./save-lists.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fsave-lists%2Fsave-lists.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';
installComponents(component, {VContainer,VImg})
