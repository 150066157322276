import { Component, Prop, PropSync, Ref, Vue, Watch } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';

const logger = new Logger('excel-dialog');
const articleModule = namespace('article');

@Component({
  name: 'excel-dialog',
  components: {},
})
export default class ExcelDialog extends Vue {
  @Ref('observer-excel-dialog-form')
  private observerExcelDialogForm!: any;

  @articleModule.Action('uploadExcel')
  private actionsUploadExcel!: any;

  @Prop({ default: false })
  private dialog!: boolean;

  hasFile: boolean = false;
  isLoading: boolean = false;
  hasUpdatedRecords: boolean = false;
  file: any = null;
  updatedRecords = '';
  insertRecords = '';

  private onChange(e: any) {
    this.hasUpdatedRecords = false;
    const isInputHasFile = e && e.name ? true : false;
    this.hasFile = isInputHasFile;
  }

  async uploadExcel(payload: any) {
    const result = await this.observerExcelDialogForm.validate();

    this.isLoading = true;
    if (result) {
      await this.actionsUploadExcel({
        file: this.file,
        targetFolder: '',
      })
        .then((result: any) => {
          console.log('uploadExcel result :>> ', result);
          this.hasUpdatedRecords = true;
          this.updatedRecords = result.updateRecords;
          this.insertRecords = result.insertRecords;
          this.isLoading = false;
          this.file = null;
          this.hasFile = false;
        })
        .finally(() => (this.isLoading = false));
    } else {
      this.isLoading = false;
    }
  }

  clickClose() {
    this.$emit('click:close');
  }

  async addDropFile(e: any) {
    this.file = e.dataTransfer.files[0];
    this.onChange(this.file);
    this.$nextTick(async () => {
      await this.observerExcelDialogForm.validate();
    });
  }
}
