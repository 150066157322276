import { render, staticRenderFns } from "./d4y-table.html?vue&type=template&id=49e14f0e&scoped=true&"
import script from "./d4y-table.ts?vue&type=script&lang=ts&"
export * from "./d4y-table.ts?vue&type=script&lang=ts&"
import style0 from "./d4y-table.scss?vue&type=style&index=0&id=49e14f0e&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "49e14f0e",
  null
  
)

/* custom blocks */
import block0 from "./d4y-table.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fcomponents%2Fd4y-table%2Fd4y-table.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAlert } from 'vuetify/lib/components/VAlert';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VAlert,VCard,VCol,VDataTable,VIcon,VRow})
