import { Component, Vue } from 'vue-property-decorator';
import { namespace } from 'vuex-class';
import { Logger } from 'fsts';
import ExcelDialog from './excel-dialog/excel-dialog.vue';
import rfd, { ReportFilterData } from '@/shared/model/smallPayloadModels/reportFilterData';
import { SaveList } from '@/shared/model/saveList';

const logger = new Logger('settings');
const articleModule = namespace('article');

@Component({
  components: { ExcelDialog },
})
export default class SettingsView extends Vue {
  @articleModule.Action('getExcelReportFromSystem')
  private actionGetExcelReportFromSystem!: any;
  @articleModule.Action('getArticlesReport')
  private actionGetArticlesReport!: any;
  private isLoadingReport = false;

  //#region ExcelDialogLogic
  dialogExcel = {
    show: false,
    model: {},
    OnClose: () => {
      this.dialogExcel.show = false;
    },
  };

  private openExcelDialog(item: any) {
    console.log('showExcelDialog item :>> ', item);
    this.dialogExcel.show = true;   
  }

  private outputDataInExcelFile(item: any) {
    this.isLoadingReport = true;   
    let reportType="group";
    this.actionGetExcelReportFromSystem(reportType).finally(() => {
      this.isLoadingReport = false;

    });  
    this.isLoadingReport = true;   
    reportType="all";
    this.actionGetExcelReportFromSystem(reportType).finally(() => {
      this.isLoadingReport = false;

    });  
  }
  //#endregion
}
