import { render, staticRenderFns } from "./settings.html?vue&type=template&id=54779b6c&scoped=true&"
import script from "./settings.ts?vue&type=script&lang=ts&"
export * from "./settings.ts?vue&type=script&lang=ts&"
import style0 from "./settings.scss?vue&type=style&index=0&id=54779b6c&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "54779b6c",
  null
  
)

/* custom blocks */
import block0 from "./settings.json?vue&type=custom&index=0&blockType=i18n&issuerPath=%2Fapp%2Fsrc%2Fviews%2Fsettings%2Fsettings.vue"
if (typeof block0 === 'function') block0(component)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
installComponents(component, {VCard,VCardActions,VCardTitle,VCol,VContainer,VRow})
